.container {
  height: 85vh;
  display: flex;
  justify-content: center;
}

.text {
  font-weight: bold;
  font-size: var(--font-size-medium);
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 0.1rem;
  text-align: center;
  margin: auto;
}