.container {
  height: auto;
  display: flex;
  flex-direction: column;
}

.intro {
  margin: auto;
  text-align: center;
  font-size: var(--font-size-large);
  letter-spacing: 0.1rem;
  font-weight: bold;
  font-family: "Roboto Mono";
  overflow: hidden;
  margin-top: 1%;
  margin-bottom: 1%;
}

.subintro {
  font-weight: bold;
  font-size: var(--font-size-normal);
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 0.1rem;
  text-align: center;
}