.project_box {
  align-items: center;
}

.project_image {
  margin-top: 20px;
  margin-bottom: 20px;
  filter: brightness(80%);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.project_title {
  font-size: var(--font-size-sortalarge);
  font-weight: bolder;
}

.project_description {
  font-size: var(--font-size-medium);
}

.project_buttons {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(161, 106, 118);
  color: black;
}

.project_buttons:hover {
  background-color: rgb(161, 106, 118);
  border-color: rgb(161, 106, 118);
  color: white;
}

.project_buttons.dark-theme {
  color: white;
}

@media only screen and (max-width: 992px) {
  .project_box{
    flex-direction: column;
  }
}