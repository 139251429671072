.container {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.intro {
  margin: auto;
  text-align: center;
  font-size: var(--font-size-large);
  letter-spacing: 0.1rem;
  font-weight: bold;
  font-family: "Roboto Mono";
  overflow: hidden;
}

.poke-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.poke-image {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 100%;
  height: auto;
}