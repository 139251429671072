.projects {
  margin-top: 35vh;
}

.project_intro {
  margin: auto;
  text-align: center;
  font-size: var(--font-size-large);
  letter-spacing: 0.1rem;
  font-weight: bold;
  font-family: "Roboto Mono";
  overflow: hidden;
  padding-top: 1%;
  padding-bottom: 1%;
}

@media only screen and (max-width: 1200px) {
  .project_intro {
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
