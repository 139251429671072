.about {
  height: auto;
}

.about_intro {
  margin: auto;
  text-align: center;
  font-size: var(--font-size-large);
  letter-spacing: 0.1rem;
  font-weight: bold;
  font-family: "Roboto Mono";
  overflow: hidden;
  margin-top: 1%;
  margin-bottom: 1%;
}

.about_card {
  padding: 1rem;
  font-size: var(--font-size-medium);
  text-align: center;
}

.about_images img {
  opacity: 0.8;
}

@media only screen and (max-width: 992px) {
  .about_row {
    flex-direction: column;
    align-items: center;
  }

  .about_images img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}