:root {
  --font-size-normal: 1rem;
  --font-size-large: 3rem;
  --font-size-sortalarge: 2rem;
  --font-size-medium: 1.5rem;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: rgba(106, 161, 149, 0.7);
}

body.dark-theme {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 90%;
  }
}

@media only screen and (max-width: 576px) {
  html {
    font-size: 60%;
  }
}