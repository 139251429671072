.button {
  cursor: pointer;
  border: none;
  color: black;
  padding: 7.5px 7.5px;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  background-color: rgba(106, 161, 149, 1);
  flex: 1;
  font-size: var(--font-size-normal);
}

.button.dark{
  color: white;
  background-color: rgba(80, 80, 80, 1);
}

.button:hover {
  background-color: white;
  color: black;
  transition: all 0.4s;
}