.navbar {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(106, 161, 149, 1);
  color: black;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 10;
}

.navbar.dark {
  background-color: rgba(80, 80, 80, 1);
  color: white;
}