.home {
  height: 100vh;
  display: flex;
}

.text-container {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.intro {
  font-weight: bold;
  font-size: var(--font-size-large);
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 1rem;
  text-align: center;
}

.subintro {
  font-weight: bold;
  font-size: var(--font-size-medium);
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 0.1rem;
  text-align: center;
}
